const DynamicLoader = ({ s, pageID, selectionCheck, onUploadHandler }) => {
  //let pageID = 0;

  const aPath = s.pages[pageID].assets_path;
  let meas_counter = 0;
  let inp_collection = [];
  for (let i = 0; i < s.pages.length; i++) {
    let navLinkInd = document.getElementById(i);
    //console.log("HELP ME", document.getElementById(i) )
    if (navLinkInd) {
      if (navLinkInd.className.includes("btn-active")) {
        navLinkInd.className = navLinkInd.className.replace(
          "btn-active",
          "btn-visited"
        );
        var spansInd = navLinkInd.getElementsByTagName("span");
        spansInd[0].className = "d-none d-sm-inline";
      }
    }
  }

  const navLink = document.getElementById(pageID);
  if (navLink) {
    if (navLink.className.includes("disabled-link")) {
      navLink.className = navLink.className.replace("disabled-link", "");
    }
    if (
      navLink.className.includes("btn-visited") &&
      !navLink.className.includes("btn-active")
    ) {
      navLink.className = navLink.className.replace(
        "btn-visited",
        "btn-active"
      );
    }
    if (navLink.className.includes("btn-deactive")) {
      navLink.className = navLink.className.replace(
        "btn-deactive",
        "btn-active"
      );
      // navLink.className = navLink.className.replace("btn-deactive", "btn-visited");
    }
    if (navLink.className.includes("btn-active")) {
      var spans = navLink.getElementsByTagName("span");
      spans[0].className = "d-inline";
    }
  }

  return (
    <div className="mt-5">
      {s.pages[pageID].pre_headline ? (
        <h2 className="text-center text-secondary mb-0">
          {s.pages[pageID].pre_headline}
        </h2>
      ) : null}
      {
        // QUESTIONS LOOP
        s.pages[pageID].questions.map((question, q_ind) => (
          <div key={q_ind} className="question-section mb-4">
            {q_ind > 0 ? <hr className="mt-5 mb-5" /> : ""}
            <div className="mt-1 ">
              <h2 className="text-center">{question.title}</h2>
              {question.description ? (
                <h5 className="text-center">{question.description}</h5>
              ) : (
                ""
              )}
            </div>
            <div
              className={
                pageID === 6
                  ? "w-100 justify-content-center"
                  : "w-100 d-flex justify-content-center"
              }
            >
              <div
                className={
                  question.className
                    ? question.className
                    : "flex flex-wrap justify-content-center mt-4"
                }
              >
                {/* CHOICES LOOP */}
                {question.choices.map((c, c_ind) => (
                  <div
                    key={c_ind}
                    className={
                      c.className
                        ? c.className
                        : "p-2 flex justify-content-center"
                    }
                  >
                    <div
                      className={c.width ? c.width : "w-40 flex flex-column"}
                    >
                      {c.type.includes("btn") ? (
                        <div className="mb-0">
                          <button
                            className={
                              c.btnClass
                                ? c.btnClass
                                : "p-0 btn choice border-Default"
                            }
                            type="button"
                            id={c.id}
                            onClick={(ev) => selectionCheck(ev)}
                            act={c.act ? c.act : ""}
                          >
                            {c.type === "btn_img" ? (
                              ""
                            ) : (
                              <span className={c.class_}>{c.name}</span>
                            )}
                            {c.type === "btn_img" || c.type === "upl_btn" ? (
                              <img
                                src={aPath + c.asset}
                                alt={c.name}
                                className={
                                  c.img_class ? c.img_class : "img-fluid"
                                }
                              ></img>
                            ) : (
                              ""
                            )}
                            {c.type === "upl_btn" ? (
                              <input
                                multiple="multiple"
                                id={c.id}
                                accept="image/*"
                                type="file"
                                onClick={(ev) => selectionCheck(ev)}
                                className=""
                              ></input>
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}

                      {c.type === "input_upload" ? (
                        <div className="h-100">
                          <div
                            className={
                              c.value
                                ? "border-Active position-relative h-100 w-100 bg-lightgray"
                                : "border-Default position-relative h-100 w-100 bg-lightgray"
                            }
                          >
                            <div className="w-100 my-4">
                              <div className="flex flex-col items-center">
                                {c.value ? (
                                  <i className="fa fa-check fa-3x"></i>
                                ) : (
                                  <i className="fa fa-upload fa-3x"></i>
                                )}
                                {c.value ? (
                                  <span className="text-center px-2  pt-2 desc-lh">
                                    Bild ausgewählt!
                                  </span>
                                ) : (
                                  <span className="text-center px-2  pt-2 desc-lh">
                                    Laden Sie ein Bild hoch, wenn Sie möchten!
                                  </span>
                                )}
                              </div>
                            </div>
                            <input
                              id={c.id}
                              className="choice h-100 w-100 opacity-0 cursor-pointer position-absolute position-top"
                              multiple="multiple"
                              accept="image/*"
                              type="file"
                              onChange={(ev) => onUploadHandler(ev)}
                              //onChange={(ev)=> selectionCheck(ev)}
                            ></input>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {(c.type === "btn_img" ||
                        c.type === "textarea" ||
                        c.type === "upload" ||
                        c.type === "upload_btn") &&
                      !c.title ? (
                        <p className="flex-fill text-center mt-1 mb-3 desc-lh">
                          {c.name}
                        </p>
                      ) : (
                        ""
                      )}
                      {c.title === "." ? (
                        <span className="text-center d-block text-white mb-2">
                          &zwnj;
                        </span>
                      ) : (
                        ""
                      )}

                      {c.description ? (
                        <span className="text-center">{c.description}</span>
                      ) : (
                        ""
                      )}

                      {c.type === "radio" ? (
                        <button
                          className="choice d-flex align-items-center checkbox-choice"
                          id={c.id}
                          onClick={(ev) => selectionCheck(ev)}
                        >
                          <span
                            className="checkbox radio-Default"
                            id="test"
                          ></span>

                          <span className="w-100 ml-2">{c.name}</span>
                        </button>
                      ) : (
                        ""
                      )}

                      {c.type === "img" ? (
                        <img
                          id={c.id}
                          className={
                            c.class_
                              ? c.class_
                              : "border-Default justify-center"
                          }
                          src={
                            c.asset === "Mass-"
                              ? aPath + c.asset + s.data[0]
                              : aPath + c.asset
                          }
                          alt={c.name}
                        ></img>
                      ) : (
                        ""
                      )}

                      {c.type === "textarea" ? (
                        <>
                          <label className="mb-0" htmlFor={c.id}>
                            {c.name}
                          </label>
                          <textarea
                            id={c.id}
                            className="choice form-control w-100"
                            rows={c.row ? c.row : "5"}
                            value={c.value ? c.value : ""}
                            placeholder={c.placeholder ? c.placeholder : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                          ></textarea>
                        </>
                      ) : (
                        ""
                      )}

                      {(function () {
                        if (
                          c.type === "input_limit" &&
                          meas_counter < s.data[1]
                        ) {
                          inp_collection.push(
                            <input
                              lim={(meas_counter += 1)} // can't run the increment without random attribution
                              id={c.id}
                              className={
                                c.className
                                  ? c.className
                                  : "choice form-control"
                              }
                              // textarea"
                              value={c.value ? c.value : ""}
                              onChange={(ev) => selectionCheck(ev)}
                              placeholder={c.name}
                              type="text"
                            ></input>
                          );
                        }
                        if (
                          c.type === "input_limit" &&
                          meas_counter === s.data[1]
                        ) {
                          return (
                            <div
                              className="w-50 mx-auto"
                              lim={(meas_counter = 99)}
                            >
                              {inp_collection.map((col) => col)}
                            </div>
                          );
                        }
                      })()}

                      {c.type === "input" ? (
                        <>
                          <label className="mb-0" htmlFor={c.id}>
                            {c.name}
                          </label>

                          <input
                            id={c.id}
                            className="choice form-control"
                            value={c.value ? c.value : ""}
                            onChange={(ev) => selectionCheck(ev)}
                            placeholder={c.placeholder ? c.placeholder : ""}
                            type="text"
                          ></input>
                        </>
                      ) : (
                        ""
                      )}

                      {c.type === "input_required" ? (
                        <>
                          <label className="mb-0" htmlFor={c.id}>
                            {c.name}
                          </label>

                          <input
                            id={c.id}
                            className="choice form-control"
                            value={c.value ? c.value : ""}
                            onChange={(ev) => selectionCheck(ev)}
                            placeholder={c.placeholder ? c.placeholder : ""}
                            type="text"
                            required
                          ></input>
                        </>
                      ) : (
                        ""
                      )}

                      {c.type === "email" ? (
                        <>
                          <label className="mb-0" htmlFor={c.id}>
                            {c.name}
                          </label>

                          <input
                            id={c.id}
                            className="choice form-control"
                            value={c.value ? c.value : ""}
                            onChange={(ev) => selectionCheck(ev)}
                            placeholder={c.placeholder ? c.placeholder : ""}
                            type="email"
                            required
                          ></input>
                        </>
                      ) : (
                        ""
                      )}

                      {c.type === "date_input" ? (
                        <>
                          <label className="mb-0" htmlFor={c.id}>
                            {c.name}
                          </label>
                          <input
                            id={c.id}
                            className="choice form-control"
                            onChange={(ev) => selectionCheck(ev)}
                            type="date"
                          ></input>
                        </>
                      ) : (
                        ""
                      )}

                      {c.type === "upload" ? (
                        <>
                          <label className="mb-0" for={c.id}>
                            <img
                              src={aPath + c.asset}
                              alt={c.name}
                              className="img-fluid"
                            ></img>
                          </label>
                          <input
                            multiple="multiple"
                            id={c.id}
                            name="filename"
                            accept="image/*"
                            type="file"
                            onClick={(ev) => selectionCheck(ev)}
                            className="btn btn-block choice border-Default "
                          ></input>
                        </>
                      ) : (
                        ""
                      )}

                      {c.type === "select" ? (
                        <>
                          <label className="mb-0" htmlFor={c.id}>
                            {c.name}
                          </label>
                          <select
                            id={c.id}
                            className="choice form-control"
                            value={c.value ? c.value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                            required
                          >
                            {c.options.map((o) => (
                              <option key={o} value={o}>
                                {o}
                              </option>
                            ))}
                          </select>
                        </>
                      ) : (
                        ""
                      )}

                      {c.type === "select_required" ? (
                        <>
                          <label className="mb-0" htmlFor={c.id}>
                            {c.name}
                          </label>
                          <select
                            id={c.id}
                            className="choice form-control"
                            value={c.value ? c.value : ""}
                            onChange={(ev) => selectionCheck(ev, true)}
                            required
                          >
                            {c.options.map((o) => (
                              <option key={o[1]} value={o[0]}>
                                {o[1]}
                              </option>
                            ))}
                          </select>
                        </>
                      ) : (
                        ""
                      )}

                      {/* {c.type==="upload" ? (
                          <input multiple="multiple"
                              id={c.id}
                              accept="image/*"
                              type="file"
                              className="btn choice border-Default h-100 w-100 opacity-0 cursor-pointer">
                          </input>
                      ): ("")
                      } */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
};

export default DynamicLoader;
