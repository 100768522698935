import "iframe-resizer/js/iframeResizer.contentWindow.js";
import Navigation from "./components/Navigation";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

import selections_no_id_json from "./selections_no_id";

import Form from "./components/Form";
import Persons from "./components/Persons";
import Style from "./components/Style";
import Wishes from "./components/Wishes";
import Budget from "./components/Budget";
import Data from "./components/Data";
import Contact from "./components/Contact";

import "./App.css";

const App = () => {
  // Debug flag, enables debug buttons and unconstrained navigation
  const DEBUG = false;

  // Backend API URL
  const API_HOST = "https://lead.ad-digital.de/api";
  //const API_HOST = "https://lead-backend.dev/api";

  const [show, setShow] = useState(false);
  //let selections_no_id_json;

  // Get client_id and questionnaire_id from URL
  // request questionnaire from backend
  // if no parameter are provided, use local questionnaire "selections_no_id.json"
  const [initialConfig, setInitialConfig] = useState(-1);
  const [selections, setSelections] = useState(selections_no_id_json);
  const [cID, setCID] = useState(3);
  const [qID, setQID] = useState(3);

  function get_config() {
    let queryParams, q_client, q_quest; //q_client = 3, q_quest = 3
    //console.log("initialConfig: ", initialConfig);
    if (true) {
      //initialConfig === -1 ){
      queryParams = new URLSearchParams(window.location.search);
      q_client = queryParams.get("client");
      q_quest = queryParams.get("questionnaire");
      console.log(`\n\n\nURL PARAMS quest: ${q_quest}  client: ${q_client}`);

      if (q_quest === null && q_client === null) {
        /*        console.log(`\n\n\n!!!>>>>>>>>>>>>>>>>>>>>
        \nNO URL PARAMETER PROVIDED\nUSING LOCAL DEVELOPMENT QUESTIONNAIRE!
        \nURL PARAMS quest: ${q_quest}  client: ${q_client}\n<<<<<<<<<<<<<<<<<<<<!!!
        \nUSING client_id:3 and questionnaire_id: 3\n\n\n`);*/
        setCID(3);
        setQID(3);
      } else {
        //console.log("get_config: set setInitialConfig: ", initialConfig);
        setInitialConfig(0);
        //console.log("get_config: set setInitialConfig2: ", initialConfig);
        //console.log(`REQUESTING CONFIG FROM BACKEND FOR CLIENT: ${q_client} QUESTIONNAIRE: ${q_quest} `);
        const db_resp = db_get(
          `client/${q_client}/questionnaire/${q_quest}`,
          extract_config
        ); //db_get(`client/3/questionnaire/3`)
        setCID(q_client);
        setQID(q_quest);
        //console.log("db_resp", db_resp);
        extract_config(db_resp);
        //TODO: extract JSON and provide it to state var

        // Generate unique leads ID based on UNIX timestamp

        // if( selections_no_id_json) {
        // //if( ! selections_no_id_json.id ) {
        //   // ${q_client}_${q_quest}_${id}
        //   selections_no_id_json.id = (new Date()).getTime().toString(16);
        //   console.log(`UNIQUE QUESTIONNAIRE ID: ${selections_no_id_json.id} (selections.id)`)
        //   console.log("selections\n", selections_no_id_json)
        // }
      }
      //setInitialConfig(1);
    }
  }

  //const [selections, setSelections] = useState(selections_no_id_json)

  // make this blocking to load JSON before further execution of other code
  function extract_config(x) {
    console.log("extract_config: setInitialConfig: ", initialConfig);
    if (false) {
      //initialConfig <= 0) {
      //let x= db_get(`client/${q_client}/questionnaire/${q_quest}`) //db_get(`client/3/questionnaire/3`)
      console.log("GET CONFIG\n ", JSON.parse(x["questions_json"]));
      if (x["questions_json"]) {
        //selections_no_id_json = x["questions_json"];
        setSelections(JSON.parse(x["questions_json"]));
        //setSelections({...selections})
        console.log("extract_config: SET setInitialConfig: ", initialConfig);
        setInitialConfig(1);
      }
    }
  }
  //get_config();

  if (true) {
    //initialConfig === 1 ) {
    //console.log("after_configcheck", selections);
    if (!selections.id) {
      //console.log("before indexer");
      //indexer(selections_no_id_json);
      // ${q_client}_${q_quest}_${id}
      indexer(selections);
      selections.id = new Date().getTime().toString(16);
      /*console.log(
        `UNIQUE QUESTIONNAIRE ID: ${selections.id} (selections.id), V: ${selections.version}`
      );*/
      //console.log("selections\n", selections_no_id_json)
      //setInitialConfig(2);
      let actualInitialConfig = initialConfig;
      //console.log("main: set initialConfig: ", initialConfig);
      setInitialConfig((actualInitialConfig) => actualInitialConfig + 1);
    }
  }

  //const location = useLocation();
  // const [selections, setSelections] = useState(selections_json)
  // const [s, setS] = useState(selections_json)

  //const [s, setS] = useState(selections_no_id_json)
  const [sendOK, setSendOK] = useState(3);

  const [forwardBtn, setForwardBtn] = useState(true);
  const [page, setPage] = useState(1);

  function exportSelection() {
    var expStr = "";
    expStr +=
      "Version: " + selections.version + "\nLead-ID: " + selections.id + "\n";
    for (var page = 0; page < selections.pages.length; page++) {
      for (
        var question = 0;
        question < selections.pages[page].questions.length;
        question++
      ) {
        expStr +=
          "\n" + selections.pages[page].questions[question].title + "\n";
        for (
          var choice = 0;
          choice < selections.pages[page].questions[question].choices.length;
          choice++
        ) {
          if (
            selections.pages[page].questions[question].choices[choice].value
          ) {
            if (
              selections.pages[page].questions[question].choices[choice]
                .value === true
            ) {
              expStr +=
                "\t- " +
                selections.pages[page].questions[question].choices[choice]
                  .name +
                "\n";
            } else {
              // expStr += "\t" + selections.pages[page].questions[question].choices[choice].name + "\n"
              expStr +=
                "\t\t- " +
                selections.pages[page].questions[question].choices[choice]
                  .value +
                "\n";
            }
          }
        }
      }
    }
    console.log(expStr);
  }

  function incButton(sig) {
    //console.log("btn_page", page, sig);
    let actualPage = page;
    actualPage >= 8
      ? (actualPage = 8)
      : setPage((actualPage) => actualPage + 1);
    //console.log("incBtn_loc: ", location.pathname)
    //history.push(navArray[page]);
    scrollToTop();
  }

  function decButton() {
    //console.log("btn_page", page);
    let actualPage = page;
    actualPage <= 1
      ? (actualPage = 8)
      : setPage((actualPage) => actualPage - 1);
    scrollToTop();
  }

  function scrollToTop() {
    if ("parentIFrame" in window) {
      window.parentIFrame.scrollTo(0, 0);
    }
  }

  function disableForwardBtn(state) {
    //var actualforwardBtn = forwardBtn;
    setForwardBtn((forwardBtn) => (forwardBtn = state));
  }

  function idToInt(id) {
    var pi, qi;
    pi = id.indexOf(".", 0);
    const p = parseInt(id.substring(0, pi));
    qi = id.indexOf(".", pi + 1);
    const q = parseInt(id.substring(pi + 1, qi));
    const c = parseInt(id.substring(qi + 1, id.length));
    return [p, q, c];
  }

  function selectionCheck(ev, text) {
    const id = ev.currentTarget.id;
    const intID = idToInt(id);
    const page = intID[0];
    const question = intID[1];
    const choice = intID[2];
    const questions = selections.pages[page].questions[question];
    //console.log("sC ", questions.max_answers);
    if (questions.max_answers === 1) {
      for (var i = 0; i < questions.choices.length; i++) {
        questions.choices[i].value = false;
      }
      questions.choices[choice].value = true;
    } else {
      if (ev.type === "change") {
        // for text inputs
        questions.choices[choice].value = ev.currentTarget.value;
      } else if (ev.type === "click") {
        // for buttons
        questions.choices[choice].value = !questions.choices[choice].value;
      }
    }
    if (page === 0 && question === 0) {
      selections.data = [
        questions.choices[choice].asset,
        questions.choices[choice].num_meas,
      ];
    }
    // if (page === 2 && question === 0) {
    //   let upl = document.getElementsByClassName("upload_show")[0];
    //   let tggl = document.getElementsByClassName("toggle")[0];
    //   if (
    //     ev.currentTarget.getAttribute("act") === "toogle" ||
    //     tggl.className.includes("border-Active")
    //   ) {
    //     if (upl.className.includes("d-none")) {
    //       upl.className = upl.className.replace(" d-none", " d-block");
    //     }
    //   }
    // }
    if (ev.target.files) {
      if (ev.target.files[0]) {
        questions.choices[choice].value = ev.target.files[0].name;
      }
    }
    setSelections({ ...selections, choices: selections.choices });
    colorCheck(id);
    checkForward(selections.pages[page].questions, page);
    //console.log("SELECTIONS\n", selections);
  }

  function colorCheck(elem_id) {
    var btns, id;

    if (elem_id) {
      id = elem_id;
    } else {
      btns = document.getElementsByClassName("choice");
      id = btns[0].id;
    }
    const page = idToInt(id)[0]; //parseInt(id.substring(0 , pi))
    var i, n;
    const pages = selections.pages[page];
    //console.log("colC id: ", id);

    // let navLink = document.getElementById(page)
    // if( navLink.className.includes("disabled-link") ) {
    //     navLink.className = navLink.className.replace("disabled-link", "");
    // }
    for (i = 0; i < pages.questions.length; i++) {
      for (n = 0; n < pages.questions[i].choices.length; n++) {
        //console.log("colC ", n, id);
        var btn = document.getElementById(pages.questions[i].choices[n].id);
        // console.log("n\n", n);
        // console.log("HKSDKJHDKJSJHDKJ\n", btn);
        if (btn) {
          if (
            btn.className.includes("border-Active") ||
            btn.className.includes("border-Default") ||
            btn.className.includes("checkbox-choice")
          ) {
            if (pages.questions[i].choices[n].value === true) {
              btn.className = btn.className.replace(
                " border-Default",
                " border-Active"
              );
            } else {
              btn.className = btn.className.replace(
                " border-Active",
                " border-Default"
              );
            }
          } else if (
            btn.parentNode.className.includes("border-Active") ||
            btn.parentNode.className.includes("border-Default")
          ) {
            if (pages.questions[i].choices[n].value === true) {
              btn.parentNode.className = btn.parentNode.className.replace(
                " border-Default",
                " border-Active"
              );
            } else {
              btn.parentNode.className = btn.parentNode.className.replace(
                " border-Active",
                " border-Default"
              );
            }
          }
          if (btn.className.includes("checkbox-choice")) {
            if (pages.questions[i].choices[n].value === true) {
              //console.log("checkbox_C D->A ",btn.firstChild.className, btn.id)
              btn.firstChild.className = btn.firstChild.className.replace(
                " radio-Default",
                " radio-Active"
              );

              // btn.className = btn.className.replace(" border-Gray", " border-Active");
            } else {
              //console.log("checkbox_C A->D ",btn.firstChild.className, btn.id)
              btn.firstChild.className = btn.firstChild.className.replace(
                " radio-Active",
                " radio-Default"
              );
              // btn.className = btn.className.replace(" border-Active", " border-Gray");
            }
          }
        }
      }
    }
    // if (page === 2) {
    //   let tggl = document.getElementsByClassName("toggle")[0];
    //   if (tggl.className.includes("border-Active")) {
    //     let upl = document.getElementsByClassName("upload_show")[0];
    //     if (upl.className.includes("d-none")) {
    //       upl.className = upl.className.replace(" d-none", " d-block");
    //     }
    //   }
    // }
  }

  function checkForward(questions, page) {
    let counter = Array(questions.length).fill(0);
    let forW = Array(questions.length).fill(false);
    //let list = document.getElementsByClassName("choice");
    for (let qI = 0; qI < questions.length; qI++) {
      for (let cI = 0; cI < questions[qI].choices.length; cI++) {
        if (questions[qI].choices[cI].value) counter[qI] += 1;
      }
      /*console.log(
        "min/max/counter answers: ",
        questions[qI].min_answers,
        questions[qI].max_answers,
        counter[qI]
      );*/
      if (
        counter[qI] >= questions[qI].min_answers &&
        counter[qI] <= questions[qI].max_answers
      ) {
        forW[qI] = true;
      } else if (
        questions[qI].min_answers === 0 &&
        questions[qI].max_answers === 0
      ) {
        //forW[qI] = true; // BUG??
      }
    }
    /*    console.log("selec", forW);
    console.log(
      "selec2",
      forW.every((e) => e === true)
    );*/
    if (forW.every((e) => e === true)) {
      selections.pages[page].disable_forward = false;
      //let li = document.getElementsByName("forward");
      //li[0].disabled = false;
      //console.log(li[0]);
      //console.log("enable fB", selections.pages[page].disable_forward);
      //disableForwardBtn(false);
    }
  }

  // Filles all choices' id fields based on their order
  function indexer(sjs) {
    try {
      for (var p = 0; p < sjs.pages.length; p++) {
        for (var q = 0; q < sjs.pages[p].questions.length; q++) {
          for (var c = 0; c < sjs.pages[p].questions[q].choices.length; c++) {
            sjs.pages[p].questions[q].choices[c].id = `${p}.${q}.${c}`;
          }
        }
      }
    } catch (error) {
      console.error(
        `Got an error trying to read the selections.json file: ${error.message}`
      );
    }
  }

  const dev_json = {
    client_json: {
      name: "App POST Test",
      logo_path: "/testclient/logo.jpg",
      css_path: "/testclient/main.css",
      questions_json: "Please insert questions_JSON",
    },
    lead_json: {
      client_id: 7,
      lead_json: "lead_results_json_placeholder",
    },
  };

  // async function db_get(endpoint, callback){
  //   const response = fetch(API_HOST + "/" + endpoint)
  //     // .then(res => res.json())
  //     // .then(data => {
  //     //   callback(data);
  //     // })
  //     .catch(error => console.warn("GET REQUEST ERROR:\n", error));
  //     const resp = response.json();
  //   return await resp
  // }

  function db_get(endpoint, callback) {
    fetch(API_HOST + "/" + endpoint)
      .then((res) => res.json())
      .then((data) => {
        callback(data);
      })
      .catch((error) => console.warn("GET REQUEST ERROR:\n", error));
  }

  function db_post(endpoint, req_json) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req_json),
    };
    fetch(API_HOST + "/" + endpoint, requestOptions).then((response) =>
      console.log(response.json())
    );
  }

  function db_put(endpoint, id, req_json) {
    const dev_json = {
      client_json: {
        name: "App PUT Test",
        logo_path: "/testclient/logo.jpg",
        css_path: "/testclient/main.css",
        questions_json: "Please insert questions_JSON",
      },
      lead_json: {
        client_id: 7,
        lead_json: "PUT TEST lead_results_json_placeholder",
      },
    };
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dev_json[req_json]),
    };
    fetch(API_HOST + "/" + endpoint + "/" + id, requestOptions).then(
      (response) => console.log(response)
    );
  }

  function onUploadHandler(ev) {
    //console.log("FILE UPLOAD\n", ev.target.files);
    selectionCheck(ev);
    //console.log("SELECTIONS\n", selections);
    const c_id = idToInt(ev.target.id);
    const p_id = c_id[0];
    //console.log("ID\n", selections.pages[p_id].name);

    if (ev.target.files.length > 5) {
      window.alert("Sie können maximal 5 Bilder auswählen!");
      return false;
    }
    let data = new FormData();
    let choices_str = "";
    for (var x = 0; x < ev.target.files.length; x++) {
      const f_ending = ev.target.files[x].name.substring(
        ev.target.files[x].name.lastIndexOf(".")
      );
      var new_file = new File([ev.target.files[x]], `Bild_${x}${f_ending}`); //`${p_name}_${x}${f_ending}`);
      //console.log("NEW_FILE\n", new_file["name"]);
      data.append("file", new_file);
      choices_str += `${new_file["name"]}`;
      //choices_str += (ev.target.files.length - x > 1) ? "~~" : "";
      choices_str += "~~";
    }
    //console.log("choices_str", choices_str);

    selections.pages[c_id[0]].questions[c_id[1]].choices[c_id[2]].value =
      choices_str;

    //console.log("UPLOAD_DATA\n", data[0]);
    // axios.post("http://localhost:8000/upload", data, {
    //     signal: controller.signal
    //     // receive two    parameter endpoint url ,form data
    axios({
      method: "post",
      url: `http://localhost:3001/upload/?client=${cID}&questionnaire=${qID}&uniqueID=${selections.id}`, // for server.js backend
      //url: `http://localhost/api/uploads`,  // for PHP backend
      timeout: 1000 * 3, // Wait for 5 seconds
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        // then print response status
        /*        console.log(
          `UPLOAD SUCCESSFUL STATUS: ${res.status} ${res.statusText}`
        );*/
        window.alert("Fotos erfolgreich hochgeladen!");
      })
      .catch(function (res) {
        //console.log(`UPLOAD FAILED: ${res}`);
        window.alert(
          "Leider gab es einen Verbindungsfehler,\n bitte probieren Sie es noch einmal."
        );
      });
  }

  function getExportJSON(ev) {
    let export_JO = {
      version: "0.0.1",
      id: false,
      pages: [],
    };
    let json_p, json_q, json_c, _export_label;
    export_JO.id = selections.id;
    for (var page = 0; page < selections.pages.length; page++) {
      json_p = { name: selections.pages[page].name, questions: [] };
      for (
        var question = 0;
        question < selections.pages[page].questions.length;
        question++
      ) {
        json_q = {
          title: selections.pages[page].questions[question].title,
          choices: [],
        };
        for (
          var choice = 0;
          choice < selections.pages[page].questions[question].choices.length;
          choice++
        ) {
          if (
            selections.pages[page].questions[question].choices[choice].value
          ) {
            //console.log("choice_value ", selections.pages[page].questions[question].choices[choice].value);
            _export_label =
              "export_label" in
              selections.pages[page].questions[question].choices[choice]
                ? selections.pages[page].questions[question].choices[choice]
                    .export_label
                : "";
            if (
              selections.pages[page].questions[question].choices[choice]
                .value === true
            ) {
              json_c = {
                value:
                  selections.pages[page].questions[question].choices[choice]
                    .name,
                export_label: _export_label,
              };
            } else {
              json_c = {
                value:
                  selections.pages[page].questions[question].choices[choice]
                    .value,
                export_label: _export_label,
              };
            }
            json_q.choices.push(json_c);
          }
        }
        json_p.questions.push(json_q);
      }
      export_JO.pages.push(json_p);
    }
    //console.log("export_JO ", export_JO);
    return export_JO;
  }

  function handleSubmit(ev) {
    let export_json_ = getExportJSON();
    ev.preventDefault(); // prevent page reload
    //console.log(ev);
    //console.log(typeof JSON.stringify(export_json_));
    send_lead("leads", export_json_);
    //console.log("SENT REQUEST");
    scrollToTop();
    //useScrollToTop(ref);
  }

  function send_lead(endpoint, json) {
    //console.log("SENDING LEAD JSON, ", json);
    const req_json = {
      client_id: 3,
      questionnaire_id: 3,
      lead_json: JSON.stringify(json),
    };
    //console.log("SENDING LEAD\n", req_json);
    // const requestOptions = {
    // method: 'POST',
    // headers: { 'Content-Type': 'application/json'},//'application/json', 'Access-Control-Allow-Origin': '*'},
    // body: JSON.stringify(req_json)
    // };
    //  fetch(API_HOST + '/' + endpoint, requestOptions)
    axios({
      method: "post",
      url: API_HOST + "/" + endpoint,
      timeout: 1000 * 10, // Wait for 5 seconds
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(req_json),
    })
      .then((res) => handleResponse(res), setShow(true))
      .catch(function () {
        //console.log("FAILED TO POST LEAD");
        handleResponse(0);
      });
  }

  function handleResponse(res) {
    if (res.status === 200 || res.status === 201) {
      setSendOK(true);
      //closeTab();
    } else {
      setSendOK(false);
    }
    //console.log("res.ok", res.status);
    //console.log("RESPONSE:\n", res);
    //console.log("RESPONSE:\n", res.json())
  }

  // let linkClass = DEBUG ? "flex-fill py-2 mt-0 mr-1 font-weight-bold btn-deactive rounded-0 text-white text-center text-decoration-none "
  //         : "disabled-link flex-fill py-2 mt-0 mr-1 font-weight-bold btn-deactive rounded-0 text-white text-center text-decoration-none "
  let linkClass = DEBUG
    ? "flex-1 py-2 mt-0 mr-1  btn-deactive rounded-0 text-white text-center text-decoration-none "
    : "disabled-link flex-1 py-2 mt-0 mr-1  btn-deactive rounded-0 text-white text-center text-decoration-none ";

  return true ? ( //initialConfig === 1) ?
    <Router>
      {
        //exportSelection()
      }
      <div className="">
        {/* Buttons for debugging, enabled by DEBUG flag */}
        {DEBUG ? (
          <div>
            <span>
              <strong> DEBUG MODE </strong>
            </span>

            <button onClick={() => exportSelection()} className="Dev p-02">
              Export
            </button>

            <button onClick={() => db_get("leads")} className="Dev p-02">
              GET Leads
            </button>

            <button onClick={() => db_get("clients")} className="Dev p-02">
              GET Clients
            </button>

            <button
              onClick={() => db_get("questionnaires")}
              className="Dev p-02"
            >
              GET Quests
            </button>

            <button
              onClick={() => db_get(`client/4/questionnaire/1`)}
              className="Dev p-02"
            >
              GET Q 1
            </button>

            <button
              onClick={() => db_post("leads", dev_json["lead_json"])}
              className="Dev p-02"
            >
              POST Leads
            </button>

            <button
              onClick={() => db_post("clients", dev_json["client_json"])}
              className="Dev p-02"
            >
              POST Clients
            </button>

            <button
              onClick={() => db_put("leads", "9", "lead_json")}
              className="Dev p-02"
            >
              PUT Leads
            </button>

            <button
              onClick={() => db_put("clients", "7", "client_json")}
              className="Dev p-02"
            >
              PUT Clients
            </button>

            <button
              onClick={() => indexer(selections_no_id_json)}
              className="Dev p-02"
            >
              Indexer
            </button>
          </div>
        ) : (
          ""
        )}

        {/* <Header title='Lead-App'/> */}

        <div className="d-flex">
          <Link
            className={linkClass}
            id="0"
            to="/Form"
            onClick={() => setPage(1)}
          >
            1. <span className="d-none d-sm-inline">Form</span>
          </Link>
          <Link
            className={linkClass}
            id="1"
            to="/Persons"
            onClick={() => setPage(2)}
          >
            2. <span className="d-none d-sm-inline">Personen </span>
          </Link>
          <Link
            className={linkClass}
            id="2"
            to="/Style"
            onClick={() => setPage(3)}
          >
            3. <span className="d-none d-sm-inline">Küchenstil</span>
          </Link>
          <Link
            className={linkClass}
            id="3"
            to="/Wishes"
            onClick={() => setPage(4)}
          >
            4. <span className="d-none d-sm-inline">Wünsche </span>
          </Link>
          <Link
            className={linkClass}
            id="4"
            to="/Budget"
            onClick={() => setPage(5)}
          >
            5. <span className="d-none d-sm-inline">Budget </span>
          </Link>
          <Link
            className={linkClass}
            id="5"
            to="/Data"
            onClick={() => setPage(6)}
          >
            6. <span className="d-none d-sm-inline">Angaben </span>
          </Link>
          <Link
            className={linkClass}
            id="6"
            to="/Contact"
            onClick={() => setPage(7)}
          >
            7. <span className="d-none d-sm-inline">Kontakt </span>
          </Link>
        </div>

        <Route
          exact
          path="/Form"
          render={() => (
            <Form
              disableForwardBtn={(state) => disableForwardBtn(state)}
              s={selections}
              setS={(data) => setSelections(data)}
              colorCheck={() => colorCheck()}
              selectionCheck={(data) => selectionCheck(data)}
              checkForward={(questions, page) => checkForward(questions, page)}
            />
          )}
        />
        <Route
          exact
          path="/Persons"
          render={() => (
            <Persons
              //disableForwardBtn={() => disableForwardBtn()}
              selections={selections}
              setSelections={(data) => setSelections(data)}
              colorCheck={() => colorCheck()}
              selectionCheck={(data) => selectionCheck(data)}
              checkForward={(questions, page) => checkForward(questions, page)}
            />
          )}
        />
        <Route
          exact
          path="/Style"
          render={() => (
            <Style
              //disableForwardBtn={() => disableForwardBtn()}
              selections={selections}
              setSelections={(data) => setSelections(data)}
              colorCheck={() => colorCheck()}
              selectionCheck={(data) => selectionCheck(data)}
              onUploadHandler={(ev) => onUploadHandler(ev)}
            />
          )}
        />
        <Route
          exact
          path="/Wishes"
          render={() => (
            <Wishes
              //disableForwardBtn={() => disableForwardBtn()}
              selections={selections}
              setSelections={(data) => setSelections(data)}
              colorCheck={() => colorCheck()}
              selectionCheck={(data) => selectionCheck(data)}
              checkForward={(questions, page) => checkForward(questions, page)}
            />
          )}
        />
        <Route
          exact
          path="/Budget"
          render={() => (
            <Budget
              //disableForwardBtn={() => disableForwardBtn()}
              selections={selections}
              setSelections={(data) => setSelections(data)}
              colorCheck={() => colorCheck()}
              selectionCheck={(data) => selectionCheck(data)}
              checkForward={(questions, page) => checkForward(questions, page)}
            />
          )}
        />
        <Route
          exact
          path="/Data"
          render={() => (
            <Data
              //disableForwardBtn={() => disableForwardBtn()}
              selections={selections}
              setSelections={(data) => setSelections(data)}
              colorCheck={() => colorCheck()}
              selectionCheck={(data) => selectionCheck(data)}
              onUploadHandler={(ev) => onUploadHandler(ev)}
            />
          )}
        />
        <Route
          exact
          path="/Contact"
          render={() => (
            <Contact
              //disableForwardBtn={() => disableForwardBtn()}
              selections={selections}
              sendOK={sendOK}
              setSelections={(data) => setSelections(data)}
              show={show}
              setShow={setShow}
              colorCheck={() => colorCheck()}
              selectionCheck={(data) => selectionCheck(data)}
              checkForward={(questions, page) => checkForward(questions, page)}
              getExportJSON={(data) => getExportJSON(data)}
              handleSubmit={(data) => handleSubmit(data)}
            />
          )}
        />

        <Navigation
          page={page}
          incButton={() => incButton()}
          decButton={() => decButton()}
          disableForwardBtn={(state) => disableForwardBtn(state)}
          forwardBtn={forwardBtn}
          selections={selections}
        />
      </div>
    </Router>
  ) : (
    <p>Loading App...</p>
  );
};

export default App;
