import DynamicLoader from "./DynamicLoader";
import { Modal } from "react-bootstrap";

const Contact = ({
  selections,
  show,
  setShow,
  setSelections,
  sendOK,
  selectionCheck,
  getExportJSON,
  handleSubmit,
}) => {
  return (
    <div className="mx-4 ">
      <form id="contact-form" onSubmit={(ev) => handleSubmit(ev)}>
        <DynamicLoader
          s={selections}
          pageID={6}
          selectionCheck={(data) => selectionCheck(data)}
        />
        <div className="row">
          <div className="col-12 col-md-8 mx-auto">
            <input
              type="submit"
              value="Abschicken"
              className="btn btn-lg btn-block btn-outline-primary"
            ></input>
          </div>
        </div>
      </form>
      <Modal show={show} onHide={setShow}>
        <Modal.Header closeButton>
          <Modal.Title>Vielen Dank!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {sendOK === true ? (
            <div>
              <p>Der Fragebogen wurde empfangen!</p>
              <p>
                Wir werden Sie in Kürze kontaktieren, vielen Dank für Ihr
                Interesse!
              </p>
            </div>
          ) : sendOK === false ? (
            <div>
              <p>Leider gab es ein Problem bei der Übermittlung.</p>
              <p>Bitte versuchen Sie es später noch einmal!</p>
            </div>
          ) : (
            <div>
              <p>Einen Moment bitte, Ihre Antworten werden übermittelt...</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Contact;
