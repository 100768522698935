import { useEffect } from "react";
import DynamicLoader from "./DynamicLoader";


// TODO: make component Form a class, with constructor etc.
//otherwise we can't use the event'

//class Form extends React.Component {

// all content in an array, enables also easy choice collection at submit

// const content = [
//     {
//         "h4":"Einzeilig",
//         "img_src":"1-einzeilig.png"
//     },
//     {
//         "h4":"Zweizeilig",
//         "img_src":"1-zweizeilig.png"
//     },
// ]


const Form = ({disableForwardBtn, s, setS, colorCheck, selectionCheck, checkForward}) => {

    useEffect(() => {
        colorCheck();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <DynamicLoader
                s={s}
                pageID={0}
                selectionCheck = { (data) => selectionCheck(data) }
            />
        </div>
    );
}

export default Form;
