import DynamicLoader from "./DynamicLoader";


const Data = ({selections, setSelections, colorCheck, selectionCheck, onUploadHandler}) => {

    return (
        <div>
            <DynamicLoader
                s={selections}
                pageID={5}
                selectionCheck = { (data) => selectionCheck(data) }
                onUploadHandler = { (ev) => onUploadHandler(ev) }
            />
        </div>
    )
}

export default Data
