import { useLocation, useHistory } from 'react-router-dom'
import { useEffect } from "react";

const Navigation = ({page, incButton, decButton, disableForwardBtn, forwardBtn, selections }) => {
    const location = useLocation()
    const history = useHistory();

    const navArray = [
        "/",
        "Form",
        "Persons",
        "Style",
        "Wishes",
        "Budget",
        "Data",
        "Contact"
    ]


    useEffect(() => {
        history.push(navArray[page]);
        //console.log(page, location.pathname, "Navigation")
        //console.log("navigation, fB: ", forwardBtn);
        //console.log("pageInc ", page, navArray[page]);
    }, [page, selections]) // eslint-disable-line react-hooks/exhaustive-deps


    let backButton;
    if(location.pathname !== "/" && location.pathname !== "/Form") {
        backButton =
        <div className="col-6 col-lg-3 mb-5">
            <button
            // style={{backgroundColor: "white", color:"white"}}
            className="btn btn-block btn-lg btn-primary"
            onClick={decButton}
            name="backward"
            >
                <i className="fa fa-angle-left"></i>&nbsp;&nbsp;{"Zurück"}
            </button>
        </div>
    }


    let forwardButton;
    let bgcol = selections.pages[page-1].disable_forward ? "lightgrey" : "var(--client-color)";
    if(location.pathname !== "/Contact") {
        forwardButton =
        <div className="col-6 col-lg-3 mb-5">
            <button
                className = {"btn btn-block btn-lg"}
                onClick={incButton}
                name={"forward"}
                disabled = { selections.pages[page-1].disable_forward }
                style={{backgroundColor:bgcol , color:"white"}}
            >
                {"Weiter"}&nbsp;&nbsp;<i className="fa fa-angle-right"></i>

            </button>
        </div>
    }

    return (
        <div className="row d-flex justify-content-center mt-5">
            { backButton }
            {forwardButton}
        </div>
    )
}

export default Navigation
