import { useEffect } from "react";

import DynamicLoader from "./DynamicLoader";

const Wishes = ({selections, setSelections, colorCheck, selectionCheck, checkForward}) => {

    useEffect(() => {
        colorCheck();
        //console.log("pageInc ", page, navArray[page]);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <DynamicLoader
                s={selections}
                pageID={3}
                selectionCheck = { (data) => selectionCheck(data) }
            />
        </div>
    )
}

export default Wishes
